import React from "react";
import {ToastProvider} from 'react-toast-notifications';
import {ListingDescriptionApp} from './components/ListingDescriptionApp';
import "./App.css";

function App() {
    return (
        <ToastProvider autoDismiss autoDismissTimeout={5000} placement="top-right">
            <div className="App">
                <ListingDescriptionApp />
            </div>
        </ToastProvider>
    );
}

export default App;
