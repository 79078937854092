import React from "react";
import {FaCircleNotch, FaRegCopy, FaCopy} from "react-icons/fa";
import {useToasts} from "react-toast-notifications";
import styles from "./ListingDescriptionApp.module.scss";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import {ThemeProvider, Button} from "@vacasa/react-components-lib";
import * as _ from "lodash";

interface ListingDescriptionAppRenderProps {
    unitListingDescription: string;
    generateListingDescription: Function;
    loadingApp: boolean;
    loading: boolean;
    hasPermissions: boolean;
}

export const ListingDescriptionAppRender: React.FunctionComponent<ListingDescriptionAppRenderProps> = (props) => {
    const {unitListingDescription, generateListingDescription, loadingApp, loading, hasPermissions} = props;
    const {addToast} = useToasts();

    const handleGenerateListingDescriptionButtonClick = () => {
        generateListingDescription();
    };

    const handleCopyToClipboardButtonClick = () => {
        navigator.clipboard.writeText(unitListingDescription);
        addToast("Copied!", { appearance: "success" });
    }

    return (
        <div>
            {hasPermissions ? (
                <div className="container-fluid">
                    {loadingApp ? (
                        <div className="row">
                            <div className={`col ${styles.centerContent}`}>
                                <FaCircleNotch className="fa-spin" />
                                <h6>Loading listing description generator...</h6>
                            </div>
                        </div>
                    ) : (
                        <ThemeProvider theme={_default}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <Button
                                            type="button"
                                            variant="primary"
                                            customClass={`${styles.pullRight} ${styles.generateDescriptionButton}`}
                                            disabled={loading}
                                            onClick={handleGenerateListingDescriptionButtonClick}
                                        >
                                            Generate Listing Description
                                        </Button>
                                        <label htmlFor="generated-listing-description" className={`${styles.descriptionLabel}`}>Generated Listing Description</label>
                                        {loading ? (
                                            <FaCircleNotch className={`fa-spin ${styles.centerContent}`} />
                                        ) : (
                                            <textarea id="generated-listing-description" className={`${styles.descriptionTextBox}`} readOnly rows={7} value={unitListingDescription}></textarea>
                                        )}
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            customClass={`${styles.pullRight} ${styles.copyDescriptionButton}`}
                                            disabled={loading || _.isEmpty(unitListingDescription)}
                                            onClick={handleCopyToClipboardButtonClick}
                                        >
                                            <FaRegCopy className={styles.copyIcon} />
                                            Copy to clipboard
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </ThemeProvider>
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
